import React, { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import moment from "moment"
import "yet-another-react-lightbox/dist/plugins/captions/captions.css"
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css"
import "yet-another-react-lightbox/dist/styles.css"
import { ButtonLink, replaceUnicode } from "./utils"
import { Stats, Carousel } from './render-functions'

import { NavTabs, TabPane } from "../ak-tabs/tabs"

export const WpGroupProjectDetail = ({ data, lang }) => {
  const [render, setRender] = useState(true)
  let paragraphs = []
  // let charchecker = ""

  data.innerBlocks.map((item, i) => {
    if (item.name === "core/paragraph") {
      paragraphs.push(item.attributes.content)
    }
    return -1
  })

  // paragraphs.toString()
  // console.log("ngecekParagraph === ", paragraphs.length)

  const toggleDetailPara = () => {
    let panel = document.getElementById("details-para")
    let iconBtn = document.getElementById("icon-details-btn")
    let overlayText = document.getElementById("overlay-text")
    let infoDesc = document.getElementById("info-details-desc")
    if(lang === "EN"){
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null
        overlayText.style.backgroundImage = "linear-gradient(0deg,#fff,hsla(0,0%,100%,.5))"
        iconBtn.classList.remove("fa-chevron-up")
        iconBtn.classList.add("fa-chevron-down")
        infoDesc.innerHTML = "Show More"
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
        overlayText.style.backgroundImage = "none"
        iconBtn.classList.remove("fa-chevron-down")
        iconBtn.classList.add("fa-chevron-up")
        infoDesc.innerHTML = "Show Less"
      }
    }else{
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null
        overlayText.style.backgroundImage = "linear-gradient(0deg,#fff,hsla(0,0%,100%,.5))"
        iconBtn.classList.remove("fa-chevron-up")
        iconBtn.classList.add("fa-chevron-down")
        infoDesc.innerHTML = "Tampilkan Lebih Banyak"
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
        overlayText.style.backgroundImage = "none"
        iconBtn.classList.remove("fa-chevron-down")
        iconBtn.classList.add("fa-chevron-up")
        infoDesc.innerHTML = "Tampilkan Lebih Sedikit"
      }
    } 
  }

  if (render) {
    return (
      <section className="group-section-wrapper mb-5">
        <div className={`container container-md ${data?.attributes?.className === "details-paragraph" ? "details-para": "mh-100"}`} id={`${data?.attributes?.className === "details-paragraph" ? "details-para": ""}`}>
          {data.innerBlocks.map((item, i) => {
            switch (item.name) {
              case "core/paragraph":
                return (
                  <div
                    key={`inner-paragraph-${i}`}
                    className="heading heading-basic"
                  >
                    <ReactMarkdown
                      source={item.attributes.content}
                      escapeHtml={false}
                    />
                  </div>
                )
              case "core/heading":
                return (
                  <div
                    key={`inner-heading-${item.attributes.content}${i}`}
                    className="my-4"
                  >
                    <h2 className="project-detail-title mb-1">{replaceUnicode(item.attributes.content)}</h2>
                  </div>
                )
              case "core/buttons":
                return <ButtonLink lang={lang} item={item} key={`inner-buttons${i}`} />
              case "acf/iconlist":
                return (
                  <WpIconlistNew key={`innerblocks-iconlist-${i}`} data={item} />
                )
              case "acf/labelvalues":
                return (
                  <Stats key={`innerblocks-${item.name}-${i}`} data={item} />
                )
              case "acf/acfgallery":
                if (item.acf.carousel_type === "compact") {
                  const now = moment().format("YYYY-MM-DD")
                  let length = 0
                  let hasExpiredDate =
                    data.innerBlocks.find(x => x.name === "acf/gallery") &&
                    data.innerBlocks
                      .find(x => x.name === "acf/gallery")
                      .acf.images.some(x => x.acfAttachment.expired !== null)

                  data.innerBlocks.find(x => x.name === "acf/gallery") &&
                    data.innerBlocks
                      .find(x => x.name === "acf/gallery")
                      .acf.images.map(item => {
                        const dateArr =
                          item.acfAttachment.expired &&
                          item.acfAttachment.expired.split("/")
                        const formatDate =
                          dateArr &&
                          [dateArr[2], dateArr[1], dateArr[0]].join("-")

                        if (
                          item.acfAttachment.expired &&
                          moment(formatDate).isAfter(now)
                        ) {
                          length++
                        } else {
                          return -1
                        }
                        return 1
                      })

                  if (length === 0 && hasExpiredDate) {
                    setRender(false)
                  }
                }
                return (
                  <div key={`innerblocks-carousel-${i}`} className="py-3">
                    <Carousel data={item} />
                  </div>
                )
              case "core/list":
                return (
                  <div className="heading heading-basic">
                    <ul>
                      <ReactMarkdown
                        source={item.attributes?.values ?? ""}
                        escapeHtml={false}
                      />
                    </ul>
                  </div>
                )
              default:
                return <div key={`empty-${i}`}></div>
            }
          })}
        </div>
        {data?.attributes?.className === "details-paragraph" && (
          <div className="container container-md">
            <div className="overlay-text" id="overlay-text"></div>
            <p className="details-plc-btn" id="details-plc-btn">
              <button 
                className="btn isdetail-btn" 
                id="is-detail-btn" 
                onClick={() => toggleDetailPara()}
              >
                <span id="info-details-desc">{lang === "EN" ? "Show More": "Tampilkan Lebih Banyak"}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fa fa-chevron-down" id="icon-details-btn"></i>
              </button>
            </p>
          </div>
        )}
      </section>
    )
  } else {
    return <div></div>
  }
}

export const WpIconlistNew = ({ data }) => {
  return (
    <section className="values">
      <div className="grid-container">
        {data?.acf?.list?.map((item, i) => (
          <div key={`${item.title}-${i}`} className="grid-item">
            <div className="icon-item">
              <img alt="img" src={item.icon && item.icon.sourceUrl}  width={`90`} height={`90`}/>
              <div>
                <p className="header">
                  {item.title}
                </p>
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

function generateSlug(inputString) {
  let slug = inputString.replace(/[^\w\s-]/g, '').trim().toLowerCase();
  slug = slug.replace(/\s+/g, '-');
  return slug;
}

export const UnitTypes = ({ data: projectTypes }) => {
  if (!projectTypes || projectTypes?.length === 0) return false;

  const projectTypeTabs = projectTypes.map((tab) => ({
    name: tab.typeTitle,
    target: generateSlug(tab.typeTitle)
  }))

  return (
    <>
      <NavTabs
        scrollable
        identifier="unit-type"
        outerClassname="project-detail-tabs justify-content-center smaller d-flex"
        data={projectTypeTabs}
      />
      <>
        {projectTypes.map((tab, i)=>(
          <TabPane key={generateSlug(tab.typeTitle)} id={generateSlug(tab.typeTitle)} className={ i === 0 ? "active" : ""} identifier="unit-type">
            <UnitTypesContent data={tab} />
          </TabPane> 
        ))}
      </>
    </>
  )
}

const UnitTypesContent = ({ data: projectType }) => {
  const [ bigImage, setBigImage ] = useState(null)

  useEffect(() => {
    if (projectType?.typeImages?.length > 0){
      setBigImage(projectType.typeImages[0].sourceUrl)
    }
  }, [projectType])

  if(!projectType) return null

  return (
    <div className="">
      <div className="row">
        {/* <div className="col-2 d-none d-lg-block">
          <div className="unit-type-thumbnails">
            {projectType.typeImages.map((typeImage, j)=>(
              <div 
                key={`image-${j}`} 
                className={`unit-type-thumbnail`} 
                onClick={() => {
                  setBigImage(typeImage.sourceUrl)
                }}
              >
                <img src={typeImage.sourceUrl} alt={`${projectType.typeTitle}-${j}`}/>
              </div>
            ))}
          </div>
        </div> */}
        <div className="col-12 col-lg-7">
          <img key={`image-full`} src={bigImage} alt={`${projectType.typeTitle}-full`} className="unit-type-image"/>
        </div>
        <div className="col-12 col-lg-5 pt-2 pt-lg-4">
          <div className="d-flex flex-lg-column flex-row justify-content-between align-items-start">
            <div className="unit-type-desc mb-lg-2">
              <ReactMarkdown source={projectType.typeDescription} escapeHtml={false} />
            </div>
            <div className="unit-type-specs">
              {projectType?.typeSpecs ? projectType.typeSpecs.map((spec, k)=>(
                <div key={`spec-${k}`} className="unit-type-spec">
                  <img alt={`spec-img-${k}`} src={spec.typeSpecIcon.sourceUrl} />
                  <span>{spec.typeSpecText}</span>
                </div>
              )) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}